/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import '~quill/dist/quill.snow.css';


ion-title {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  font-weight: 600;
}

app-home-list .refresher-active{
  background: #3971B6;
  z-index: 1;
}
app-help .refresher-active{
  background: #3971B6;
  z-index: 1;
}

// ion-menu{
//   width: 210px !important;
// }

ion-content::part(background){
  --offset-top: 1px;
    --offset-bottom: -1px;
}

.flex {
  display: flex;
}

.full-width {
  width: 100% !important;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.align-self-center {
  align-self: center;
}

.align-self-end {
  align-self: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-space-around {
  justify-content: space-around;
}

.justify-content-space-evenly {
  justify-content: space-evenly;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-space-end {
  justify-content: flex-end;
}

.align-items-center {
  align-items: center;
}

.flex-column {
  flex-direction: column;
}
.text-right {
    float: right;
    padding-top: 5px;
    padding-right: 15px;
}
.caledr_icon{
  position: absolute;
  right: 2px;
  background-color: #fff;
}
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
}

.text-tiny {
  font-size: 11px !important;
}

.text-small {
  font-size: 12px !important;
}

.text-medium {
  font-size: 14px !important;
}

.text-normal {
  font-size: 16px !important;
}

.text-large {
  font-size: 24px !important;
}

.text-xlarge {
  font-size: 36px !important;
}

.text-xxlarge {
  font-size: 48px !important;
}

.radius {
  border-radius: 8px !important;
}
.text-justify{
  text-align: justify;
}
ion-header{
  // z-index: 11 !important;
}

.divider {
  background: var(--ion-color-light);
  margin: 8px 0;
  height: 1px;
  width: 100%;
}

.red{ background-color: #3971B6; color: #fff;}

#tab-button-appoint, #tab-button-profile, #tab-button-home-list, #tab-button-support, #tab-button-help{
  ion-label{
    font-size: 12px;
    text-align: center;
  }
}


.bold {
  font-weight: bold;
}
.text-center { text-align: center; }
.font-bold { font-weight: 600; }
.color-white { color: var(--ion-color-primary-contrast); }
.color-black { color: var(--ion-color-warning-contrast); }
.p-0 { padding:0; }
.px-0 { padding-left: 0; padding-right: 0; }
.py-0 { padding-top: 0; padding-bottom: 0; }
.d-flex { display: flex;}
.mt-3 { margin-top: 3em; }
.primary-blue-btn {
  --background: var(--ion-color-primary-blue);
  --box-shadow: none;
  // padding:10px;
  a {
    color: #fff; text-decoration: none;
  }
}
.btn-grey {
  --background: #D3D2D2;
  color: #303030;
  text-transform: capitalize;
  --border-radius: 20px;
  --padding-start: 25px;
  --padding-end: 25px;
  --padding-bottom: 0px;
  height: 35px;
  font-size: 16px;
}
.pull-left { float: left; font-weight: 600;}
.pull-right { float: right; }
.justify-content-between { justify-content: space-between; }
.align-items-start { align-items: flex-start;}
ion-header::after { background-image: none !important; }
.btn-white {
  --background: #fff;
  color: #000;
  text-transform: capitalize;
  --border-radius: 10px !important;
  margin: 1em 0;
  height: 43px;
  font-size: 16px;
}

// custome styles
.btn-violet {
    width: 100%;
    margin: 0 auto;
    display: block;
    --background: var(--ion-color-dark-blue) !important;
    color: var(--ion-color-primary-contrast) !important;
    // padding: 15px 10px;
    --border-radius: 5px;
    font-weight: 600;
    font-size: 16px;
    height: 45px;
    text-transform: capitalize;
  // --background: #14224A !important;
  // text-transform: capitalize;
  // --border-radius: 10px;
  // --padding-start: 25px;
  // --padding-end: 25px;
  // --padding-bottom: 0px;
  // height: 45px;
  // font-size: 16px;
}
.btn-primary-blue {
  --background: #3971B6;
  border: 1px solid #3971B6;
  border-radius: 10px;
  height: 55px;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 16px;
  margin-top: 20px;
}
.mt-1 { margin-top:1em; }
.mb-1 { margin-bottom: 1em; }

ion-toolbar {
  .dash_header{
    padding-left: 0px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

ion-searchbar{
  padding: 0px !important;
  .searchbar-input{
      background: rgba(236, 236, 236, 1) !important;
      border: 1.5px solid #FFFFFF !important;
      border-radius: 19px !important;
      box-shadow: none !important;
      padding-inline-start: 35px !important;
      padding-inline-end: 10px !important;
      padding-top: 10px !important;
      padding-bottom: 10px !important;
      font-size: 12px !important;
      font-weight: 400 !important;
      line-height: 14px !important;
  }
  .searchbar-search-icon.sc-ion-searchbar-md{
      left: 10px !important;
      top: 10px;
      width: 17px;
      height: 17px;
    background-image: url(../src/assets/search.png) !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  color: transparent;
  }
  .searchbar-clear-icon.sc-ion-searchbar-md {
    float: right;
}
}

// login with number
* { font-family: var(--font); }
.Login_With_Num {
  // --background: var(--ion-color-primary-blue); text-align: center;
      ion-grid.md.hydrated {
        // position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        // width: 100%;
        padding: 20px;
          h1 { font-weight: 600; }
          p { margin-bottom: 1em; font-size: 14px; }
          h5 { font-size: 14px; padding: 0 1em; }
          h3{margin-top: 0px;}
          a{ text-decoration: none; margin-top: 1em !important; display: block; font-size: 14px; }
          h1, h3, p, h5, a { color: var(--ion-color-warning-contrast); }
          ion-input { border: 1px solid var(--ion-color-primary-contrast); background-color: var(--ion-color-primary-contrast); border-radius: 5px; }
    }
}


// login with email
.Login_With_email {
  // --background: var(--ion-color-primary-blue);
  ion-grid.md.hydrated {
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    // width: 100%;
    // padding: 20px;
    ion-label {
      float: left;
    width: 100%;
    text-align: left;
    font-weight: 500 !important;
    font-size: 15px;
    line-height: 17px;
    color: #000;
      // color: var(--ion-color-primary-contrast);
  }
  ion-input .native-input { border-radius: 5px !important; }
  a{

    color: var(--ion-color-light-black-gray) !important;
    padding: 10px 0px;
    border-radius: 10px;
    font-weight: 600;
    font-size: 14px;
    text-decoration: none;
    float: right !important;
    text-decoration: underline;
    color: #0073C7 !important;
    // text-decoration: none; margin-top: 1em !important; display: block; font-size: 14px; color: var(--ion-color-primary-contrast);
  }
  }
}


// otp generate
.otp-generate {
h1.head {
  color: crimson;
  font-weight: 600;
  color: #3D3939;
  text-align: center;
}
a {
  text-align: center;
  display: block;
  text-decoration: none;
  margin-top: 1em;
}
img{
  margin: 0 auto;
  display: block;
}
p{
  margin-bottom: 30px;
  text-align: center;
}
.otp_col{
  text-align: center;
}
.otp-input{
  width: 50px !important;
  font-size: 18px;
  height: 50px !important;
}
}
.otp_register{
  // padding: 2em 1em 0em 1em;
  // p{
  //   color:#fff;
  // }
// h3{margin-top: 0px; color:#fff;}
}
// initial tool

.swiper-pagination-bullet-active {
  opacity: 1 !important;
  width: 25px;
  border-radius: 0px;
  height: 5px;
  --background:var(--ion-color-primary-dark-blue) !important;
}
ion-slides {
  // min-height: 576px;
  button {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
}
}
.swiper-pagination-bullet{
  --background:#B9B7B7; opacity: 1;
}
.swiper-pagination.swiper-pagination-bullets { position: absolute; bottom: 105px !important; }
.swiper-pagination-bullet-active {
  background: #14224A !important;
  border-radius: 5px !important;
}

.swiper-slide { color: var(--ion-color-primary-contrast);
.skip-text { text-align: right; display: block; margin-top: 1em; font-size: 16px; }
.slide-text {
  padding: 4em 0;
  h3 {
      font-size: 27px;
      font-weight: 600 !important;
  }
  p { font-size: 16px; padding: 0 1em; }
}
}
.slider-1 { background: url("./assets/slide-1.jpg"); height: 100vh; background-position: center top !important; background-size: cover !important; width:100%; display: block;}
.slider-2 { background: url("./assets/slide-2.jpg"); height: 100vh; background-position: center top !important; background-size: cover !important; width:100%; display: block;}
.slider-3 { background: url("./assets/slide-3.jpg"); height: 100vh; background-position: center top !important; background-size: cover !important; width:100%; display: block;}
.swiper-slide {
  -webkit-align-items: normal !important;
  align-items: normal !important;
}
.icon {
  margin-top: 20%;
  font-size: 120px;
  color: #fff;
}

.footer-md::before, .tabs-md[tabsPlacement="bottom"] > .tabbar::before {
  background-image: none !important;
}
.swiper-slide button {
  padding: 13px 20px;
}
button{
  // position: absolute;
  width: 90%;
  // bottom: 53px !important;
  // left: 17px;
  background: var(--ion-color-primary-blue);
  border-radius: 10px;
  padding: 13px 20px;
  font-weight: 700;
  color: var(--ion-color-primary-contrast);
  font-size: 15px;
  //  position: fixed;
  // bottom: 30px;
  z-index: 999;

}

.text-input{
  width: 100%;
  border: 2px solid #C4C4C4;
  float: left;
  border-radius: 8px !important;
  background-color: #fff !important;
  margin-top: 10px;
  margin-bottom: 10px;
  outline: none;
  // height: 53px;
  font-size: 14px;
  color: #000;
}
.searchbar-input-container {

  margin: 0px 0px;

.searchbar-input.sc-ion-searchbar-md {
  // border-radius: 5px !important;
  // background-color: #ECECEC;
  color: #14224A;
  position: relative;
  background: #e5f7ff !important;
  border: 1px solid #cccccc !important;
  border-radius: 5px !important;
  font-size:14px !important;
}
}

ion-col {
  // padding: 5px;
  h5{
    flex: content;
    font-size: 14px;
    color: #14224A;
    padding: 0px;
    margin: 0px;
    font-weight: bold;
    span {
      font-size: 14px;
      color: skyblue;
      padding: 0px;
      margin: 0px;
    }
  }
  h4{
    font-size: 15px;
    color: #14224A;
    padding: 0px;
    margin: 0px;
    font-weight: bold;
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

// .profile{
//   height: 200px;
//   width: 200px;
//   background-color: #6f9fd9;
// }

ion-label {
  margin-top: 0px;
  margin-bottom: 6px;
  float: left;
  width: 100%;
  text-align: left;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--ion-color-warning-contrast);
}
.alert-radio-group.sc-ion-alert-md, .alert-checkbox-group.sc-ion-alert-md {
  position: relative;
  max-height: 254px;
  border-top: 0px solid var(--ion-color-step-150, #d9d9d9);
  border-bottom: 0px solid var(--ion-color-step-150, #d9d9d9);
  overflow: auto;
}
.alert-button-group.sc-ion-alert-md button{
  bottom: 0px !important;
}
.alert-radio-button-disabled .alert-button-inner .alert-radio-icon{
  display: none;
}
.alert-radio-button-disabled .alert-button-inner .alert-radio-label{
  font-size: 20px;
}
.alert-button-group.sc-ion-alert-md {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  width: 100%;
}


// footer ui
.tab-selected.home-tab-button{
  svg path{
    // fill: var(--ion-color-primary);
    // stroke: none;
  }
}
.tab-selected{
  svg{
    width: 23px;
    path.home-lis{ 
      fill: var(--ion-color-secondary);
    }
    path.profile-svg{
      stroke: var(--ion-color-secondary);
    }
    
  }



  ion-label{
    color: var(--ion-color-primary);
    font-weight: 500;
  }
}
ion-label{
  font-weight: 400;
  font-size: 20px;
  color: #000;

  // font-weight: 500;
  // font-size: 14px;
  // color: var(--ion-color-light-gray);

}
.tab-has-label{
  svg{
    width: 30px;
  }
}




// dashboard ui

.doct_name{
  // display: flex;
  // align-items: flex-end;
  // justify-content: space-between;
  span{
    font-size: 10px;
    font-weight: 400;
    color: #fff;
    opacity: 0.5;
  }
  h3{
    font-size: 20px;
    line-height: 23px;
    color: #fff;
    font-weight: 500;
    text-align: center;
    b{
      font-size: 25px;
      line-height: 30px;
      font-weight: 500;
    }
  }
  h4{
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #1b1b1b;

    b{
      font-weight: 600;
      font-size: 13px;
      line-height: 15px;
      color: #fff;
      opacity: 0.5;
    }
  }
  .ion-float-left{
    width:60%
  }
  .ion-float-right{
    width:40%;
  }
}
.appot_patient {
  padding: 5px;
    align-items: center;
  h5 {
    flex: content;
    font-size: 13px;
    color: gray;
    padding: 0px;
    margin: 0px;
    font-weight: 400;
    overflow: hidden;
    white-space: nowrap;
    width: 116px;
    text-overflow: ellipsis;
  }
  span {
    font-size: 13px;
    color: var(--ion-color-secondary);
    margin: 0px;
    // font-weight: 600;
  }
  // img{
  //   width: 100%;
  //   max-width: 356px;
  //   height: 100px;
  //   max-height: 100px;
  //   object-fit: cover;
  //   border-top-left-radius: 7px;
  //   border-top-right-radius: 7px;
  // }
  .app_time_date{
    display: flex;
    align-items: center;
    justify-content:left;
    margin: 5px 0px 0;
  }
  small{
    display: flex;
    align-items: center;
    font-size: 12px;
    padding-right:8px;
    color: #677294;
    ion-icon{
      color: #6670dd;
      padding-right: 5px;
      font-size: 14px;
    }
  }
}
app-home-list{

  ion-card {  box-shadow: none;
    ion-slides{ border: 1px solid #FFB7B7; border-radius: 8px; }
  }
  .patient-grid {
    margin: 15px 5px 0px;
    ion-card {
      border: 1px solid rgba(102, 112, 221, 1);
      border-radius: 10px;
      box-shadow: 0px 4px 7px #28a3da47;
      padding: 5px 0px;
    }
    h4{
      margin:0px 0px 3px 10px;
    }
   }
  .swiper-pagination.swiper-pagination-bullets
  {
    position: absolute;
    bottom: 25px !important;
    right: 9px;
    left: unset;
    width: auto;
    display: grid;
  }
  .swiper-pagination-bullet-active {
    opacity: 1 !important;
    width: 10px;
    border-radius: 50px;
    height: 10px;
    --background: var(--ion-color-primary-dark-blue) !important;
  }
  .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
    display: inline-block;
    border-radius: 100%;
    opacity: 1;
    margin: 2px auto !important;
  }
  .swiper-pagination-bullet-active {
    background: #FF7070;
    width: 10px !important;
    height: 10px !important;
  }
  // .appot_patient{
  //   ion-col{

  //   }
  // }
}


// register ui
.alert-radio-group.sc-ion-alert-md button{
  bottom: 0px !important;
}
.alert-radio-label.sc-ion-alert-md {
  -webkit-padding-start: 30px;
}
.alert-radio-icon.sc-ion-alert-md {
  left: 15px;
}
.alert-button.sc-ion-alert-md{
  margin-inline-end: 25px;
}
ion-slides{
  button{
    transform: translateX(0%);
  }
}
.slot-days{
  overflow: scroll;
  ion-col {
    // margin-left: -40px;
    ion-button{
      &:first-child{ background-color: #FEDAC5; }
      // &:first-child:hover{ background-color: #f97428; }
      &:nth-child(2){ background-color: #BAD5F0; }
      // &:nth-child(2):hover{ background-color: #67aaee; }
      &:nth-child(3){ background-color: #F3B1CD; }
      // &:nth-child(3):hover{ background-color: #fe519a; }
      &:nth-child(4){ background-color: #C2D5A8; }
      // &:nth-child(4):hover{ background-color: #bbec77; }
      &:nth-child(5){ background-color: #A5D5D5; }
      // &:nth-child(5):hover{ background-color: #4df1f1; }
      &:nth-child(6){ background-color: #D6EFF6; }
      // &:nth-child(6):hover{ background-color: #3dd4fe; }
      &:last-child{ background-color: #E6B7FF; }
      // &:last-child:hover{ background-color: #c476ee; }
     }
     .day-selected{
      box-shadow: inset 0px 0px 15px #000000;
     }
}
// ion-item { transform: translateX(-38px); }
}
.slot-title {
  text-align: center;
  color: #2C3A4B;
  font-size: 20px;
  margin: 10px 0 10px;
}

.hour-select {
  display: flex;
  align-items: center;
  justify-content: center;
  ion-item { --inner-padding-end: 0; }
  ion-label.title {
    font-size: 22px !important;
    text-align: center;
    margin: 0 auto 10px;
    display: block;
  }
}
.confirm-slots {
  // height: 250px;
  // overflow: scroll;
  ion-item { --inner-padding-end: 0;  height:46px; width:100%;
  ion-label {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    border: 1px solid #0073CC;
    text-align: center;
    display: inline-block;
      cursor: pointer;
      border-radius: 10px;
      font-size: 14px;
      padding: 10px;
      width: 97%;
      text-align: center;
      font-weight: 600;
  }
  }
}
app-register .submit.primary-blue-btn{
  padding: 10px;
  font-size: 14px !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
  height: 35px !important;
}
app-register .primary-blue-btn{
  height: 55px;
  padding: 10px;
  font-size: 14px !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
}
.timepicker-dial__container{    justify-content: center !important; }
ngx-material-timepicker-content div.ng-star-inserted:first-child { left: 21px !important;}
.clock-face__number.clock-face__number--outer.ng-star-inserted { left: unset !important; }
.timepicker-button{ border-radius: 10px; padding: 10px; width: 100%; }
.timepicker-period{flex-direction: row !important; }
.timepicker-dial__item { outline: 0; }
// .swiper-wrapper { height: auto !important;}
// ngx-material-timepicker.to-time-picker .timepicker {margin-left: -155px;}


// .slot-days ion-button:(nth-child(2)) { --background: #FEDAC5; }
// .slot-days ion-button:(nth-child(3)) { --background: #FEDAC5; }
// .slot-days ion-button:(nth-child(4)) { --background: #FEDAC5; }
// .slot-days ion-button:(nth-child(5)) { --background: #FEDAC5; }
// .slot-days ion-button:(nth-child(6)) { --background: #FEDAC5; }
// .slot-days ion-button:(last-child) { --background: #FEDAC5; }




// terms and conditions
app-terms-conditions{
// .flex-parent {
//   display: flex;
// }

.jc-center {
  justify-content: center;
}
h3, h4{
  padding:0 10px;
}
p{
  padding: 0 10px;
  text-align: justify;
}
ion-button.md
{
  text-transform: capitalize !important;
  width: 100%;
}
}


// appointment page
app-appoints{
  ion-segment{
    border-bottom: 2px solid #E5E5E5;
  }
  ion-segment-button {
    --color-checked: var( --ion-color-warning-contrast);
    font-weight: 500;
    --indicator-color: transparent;
    text-transform: capitalize;
    font-size: 14px !important;
  }
  .segment-button-checked{
    border-bottom: 2px solid #127CCE;
  }
  .appointment-search{
    ion-searchbar { width:100%; margin:10px 2px; }
    .calendarSec {
      position: relative;
      width:12%;
      img {
        position: absolute;
        top: 0;
        left:4px;
      }
    }
  }
  .patient-grid-card {
    border: 1px solid #2A99E6; border-radius: 10px; margin:5px; box-shadow: none;
  p.patient-status {
    position: absolute;
    top: 5px;
    right: 10px;
    background: #57CC7E;
    color: #fff;
    font-size: 10px;
    padding: 4px 5px 4px 5px;
    margin: 0;
    border-radius: 10px;
}
}

}

// appointment details

.appointment_details {
.callinitiate {
  background-color: var(--ion-color-primary-blue);
    padding: 18px;
    box-shadow: 0px 0px var(--ion-color-primary-blue);
    border-radius: 20px;
    width: 94%;
    margin: 20px auto 0px;
  img { width:18px; }
  span { margin-left: 10px; color: #fff; }
}
.patient_appoint{
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  padding: 10px;
  align-items: center;
  img{
    border-radius: 15px;
    width: 100px;
    height: 100px;
    object-fit: contain;
  }
  .appoint_detil {
    h2{font-size: 16px;
      font-weight: 600;}
    h2, p { margin:0; color: var(--ion-color-light-black); text-transform: capitalize; }
    p { font-size: 14px; }
    .app_time_date { margin: 7px 0 !important; }
  }
  .patient-comment {
    p { margin-top: 0; margin-bottom: 10px; font-size:16px; font-weight: 500; }
    .patient-comment-content {
      background: #D9D9D9;
      padding: 15px;
      border-radius: 10px;
      font-size: 14px;
  }
  }
  .patient-meeting-actions {
    text-align: center;
    .action-img { padding: 10px; border-radius: 10px;  }
    .add-doctor{
      p {
        color: #30775F;
        font-size: 16px;
        margin: 5px 0;
        font-weight: 500;}
      .action-img{ background: #E3F6FF; }
    }
    .cancel-meeting {
      p {
        color: #FF8863;
        font-size: 16px;
        margin: 5px 0;
        font-weight: 500;}
      .action-img{ background: #FF8863;
        display: flex;
        align-items: center;
        justify-content: center; }
    }
  }

}
}

// app-cancel-meeting

app-cancel-meeting { --background: transparent;
  .cancel-meeting section {
    background: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // height: 50%;
    padding: 10px !important;
    width: 95%;
    border-radius: 15px !important;
    h3 { color: var(--ion-color-light-black); }
    .close {
      width: 50px;
      height: 50px;
      position: absolute;
      top: -20px;
      background: #fff;
      text-align: center;
      right: 0;
      border-radius: 50%;
      padding: 13px 0;
    }
    form{
      ion-textarea {
        border: 1px solid #eee;
        border-radius: 10px;
      }
    }

  }
}
ion-modal { --background: #1880d05e !important; }

// view report

.view-report {
  .head {
    display: flex;
    align-items: center;
    h3 { margin:0; }
  }
  .details{
    align-items: center;
    img { border: 1px solid #eee !important;
      width: 130px;
      height: 130px;
      object-fit: cover;
      margin: 0 auto;
      display: block; }
    .appoint_detil {
      h2, p { margin:0; color: var(--ion-color-light-black); text-transform: capitalize; }
      p { font-size: 15px; margin: 1px 0px;}
      .app_time_date { margin: 10px 0 !important; }
    }
    .patient-comment {
      p { margin-top: 0; margin-bottom: 5px; }
      .patient-comment-content {
        background: #D9D9D9;
        padding: 15px;
        border-radius: 10px;
        font-size: 14px;
    }
    }
    .report-list {
      background: #fff;
      // box-shadow: 0 0 12px -4px #b4a8a8;
      box-shadow: 0px 0px 20px rgb(0 0 0 / 8%);
      padding: 20px 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 5px;
      margin-bottom: 10px;
      .report-details {
        small.lab-name { font-size: 12px; color: #F6921E; }
        small.report-date { font-size: 12px; }
        p { margin: 0; color: var(--ion-color-light-black); font-weight: 600; }
      }
  }
  }
}


// About Us
.about-us {
  // --background: linear-gradient(180.8deg, rgba(44, 94, 222, 0.6) -0.96%, #4E70B3 80.77%);
  // p{
    // color: var(--ion-color-secondary-contrast);
    // padding:0px 0px;
    // text-align: left !important;
    // font-size: 12px;
    // line-height: 18px;
    // letter-spacing: -0.5px;
    // word-spacing: 6px;
  // }
  // h3{
  //   color: var(--ion-color-secondary-contrast);
  //   padding:0px 5px;
  // }
  img{
    width: 100%;
    border-radius: 10px;
    margin: 0 auto;
    display: block;
  }
}

// profile

.profile {
  .avatar img{
    height: 130px;
    width: 130px;
    margin: 0 auto;
    display: block;
    border-radius: 50%;
    object-fit: cover;
  }
  .playername { text-transform: capitalize; margin-bottom: 0; color: var(--ion-color-light-black); font-size:18px !important;
  margin-top:10px;
  margin-bottom: 5px;
  }
  h5 { font-size: 14px; margin: 0; color: var(--ion-color-light-black); }
  .location_view {
    p {
      // display: flex;
      // align-items: center;
      // justify-content: center;
      // padding: 0px 12px;
      // line-height: 18px;
      // letter-spacing: -0.5px;
      // word-spacing: 6px;
      // font-size: 12px;
  }
  img { width: 20px; margin-right: 10px; }
  }
  ion-accordion-group { padding: 0 10px;
    ion-accordion{
      ion-icon { display: none; }
      ion-item{
        --background: #F3F3F3;
        padding: 5px 10px;
        --border-radius: 10px;
      ion-label {
        font-size: 15px !important; padding-left: 10px; margin-bottom: 0px; font-weight: 600;
      }
      }
    .accrd_detail, .working_hours, .reviews_tab {
      h3 { font-size: 16px; color: #444444; }
      table th, table td { text-align: left; border: 0px solid #eee !important; padding: 5px 0; }
    }
    .accrd_detail, .working_hours{
      padding:0 5px 0px 15px;
    }
    .reviews_tab{
      padding:0 5px 0px 0px;
    }

    }
    .accordion-expanded {
      ion-item{
        --background: #35ADE1;
      ion-label {
        color: #fff;
      }
    }
    }
  }
}
img.pull-right.edit-profile { margin-right: 15px; }


// edit profile
.edit-profile{
  --padding-start: 10px;
  --padding-end: 10px;
  .profile-details{
    align-items: center;
    ion-img::part(image) { width:100px; height:100px; border-radius: 50% !important; object-fit: cover; }
    p { margin: 0; font-size: 14px; }
    h3 { margin: 0; color: #1b1b1b; text-transform: capitalize; }
    .change-password { text-decoration: underline; }
    .doctor-name {padding-left: 35px; }
  }
  ion-label { color: var(--ion-color-light-black); float: none; }
  ion-input { color: #8F8F8F; }
  ion-button {
    width: 100%;
    --background: #3971B6;
    height: 45px;
    font-weight: 400;
    text-transform: capitalize;
    font-size: 16px;
    margin: 10px 0 15px;
}
  .ionic-selectable {
    display: block;
    border: 2px solid #c4c4c4;
    border-radius: 5px;
    height: 45px;
    color: #000;
    padding: 0 10px;
    line-height: 12px;
    margin-top: 5px;
}
  img.input-icon {
    display: none;
    position: absolute;
    top: 45px;
    right: 14px;
    z-index: 100;
}
.cho-sefile {
  background: #0073CC;
  color: #fff;
  margin: 5px 5px 5px 0;
  // max-width: 120px;
  width:100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  float: left;
  padding: 0 5px;
  height: 30px;
  border-radius: 5px;
  position: relative;
  clear: both;
  p{
    margin: 5px 0;
  }
  .close {
    position: absolute;
    top: 0px;
    right: 0;
    font-size: 13px;
    padding: 7px;
    background: #004a83;
}
}
.cert-upload{
  height: 25px;
  width: 25px;
  margin-top: -3px;
  float: right;
}
.cert-upload-label {
  width: 85%;
  // float: left;
}
  .native-input[type=date]::-webkit-calendar-picker-indicator {
    // filter: invert(1);
    font-size: 20px;
    margin-right: 10px;
  // width: 30px;
  }
}

// change password

.change-password{
  ion-label {
    font-size: 14px !important;
    font-weight: 400;
    color: var(--ion-color-light-black);
  }
}

// invite and share
.invite-share{
  .invite-img {
    position: absolute;
    top: 42px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;

    img {
      background-color: #fff;
      border-radius: 50%;
      padding: 10px;
      width: 47%;
    }
  }
  .invite-url {
    background: #0073CC;
    color: #fff;
    padding: 12em 2em;
    text-align: center;
    margin-top: 8em;
    // height: 74.5vh;
    height: 80.5vh;
    width: 100%;
    .url-copy {
      position: relative;
      img {
        position: absolute;
        top: 7px;
        right: 3px;
        z-index: 9999;
        background: #0073cc;
      }
      ion-input {
        border: 1px solid #fff;
        border-radius: 10px;
        text-align: left;
        font-size: 13px;
        font-weight: 300;
        font-family: var(--font);
    }
    }
  }
}


// about us
.about-us {
  // h3 { color: #000; }

  img { border-radius: 10px; }
  .about_field {
    background: linear-gradient(180deg, #F5F7FF 0%, #F5F7FF 100%);
    mix-blend-mode: normal;
    border-radius: 24px 24px 0px 0px;
    padding: 10px 15px;
    margin: 12px;
    p{padding:0px 0px;
      text-align: left !important;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: -0.5px;
      word-spacing: 6px;}
  }
}



// account history

.account-history{
  ion-segment{
    border-bottom: 2px solid #E5E5E5;
  }
  ion-segment-button {
    --color-checked: var( --ion-color-warning-contrast);
    font-weight: 500;
    --indicator-color: transparent;
    text-transform: capitalize;
    font-size: 14px !important;
  }
  .segment-button-checked{
    border-bottom: 2px solid #127CCE;
  }
  .patient-grid-card {
    border: 1px solid #2A99E6; border-radius: 10px; margin:5px; box-shadow: none;
}
.pay-history { margin-top: 5px;
  .prescription, .pay {
    font-size: 9px;
    padding: 3px 8px;
    border-radius: 15px;
  }
  .prescription{ background: #eee; color: #676767; }
  .pay{ background: #57CC7E; color: #fff; }
}

}

// notification
.notification {
  --padding-start: 20px;
  --padding-end: 20px;
  // p { padding-left: 15px; }
}
.count_list{
  position: absolute;
  top: 7px;
  right: 13px;
  font-size: 10px;
  background: var(--ion-color-orange);
  border-radius: 50%;
  width: 18px;
  height: 18px;
  text-align: center;
  color: #fff;
  padding: 3px 0px;
}


// add prescription

.add-prescription {
  ion-label {
    font-size: 14px !important;
    font-weight: 400;
    color: var(--ion-color-light-black);
  }
  .prescription {
    ion-title { color: #177FD0; padding: 10px 15px; border-bottom: 2px solid #eee; }
  }
}


// set available time
.ava-smline input {
  border: 1px solid #5555;
  border-radius: 30px;
  padding: 10px 20px;
  margin: 0 auto;
  display: block;
}
.slots {
  ion-col {
  label.visit-btns {
    width: 100% !important;
    span {
      border: 2px solid #0073CC;
      /* color: #0073CC; */
      display: inline-block;
      cursor: pointer;
      border-radius: 10px;
      font-size: 14px;
      padding: 10px;
      width: 97%;
      text-align: center;
      font-weight: 600;
    }
    input[type=checkbox] { opacity: 0; position: absolute;}
    input[type=checkbox]:checked + span {
      color: #000; background-color: #E3E5FA;
    }
  }
}
}


// faq
app-faq {
  ion-toolbar {
    // --background: #147ED0 !important;
    color: #000;
  }
  .faq {
    ion-accordion {
      color: #000;
      padding: 6px 0px 0px;
      ion-item{
        ion-label {
          font-size: 16px !important;
          color: #000 !important;
        }
        ion-icon { color: #9f9f9f; font-size: 20px;
       }
      }
  }
  }
}

// blog
.blog {
  .segment-button-checked {  --color-checked: #127CCE; }
  ion-segment-button {
  --color-checked: var( --ion-color-warning-contrast);
    font-weight: 500;
    --indicator-color: transparent;
    text-transform: capitalize;
    font-size: 16px !important;
    min-width: 50px;
  }
  .tab-content {
    ion-item{
      // display: flex;
      align-items: flex-start;     margin-top: 10px;
      ion-thumbnail{
        width: 85px;
        height: 75px;
        border-radius: 5px;
        object-fit: cover;
        margin: 0px 10px 0px 0;
        img {
          border-radius: 8px; border: 1px solid #f5f5f5;
        }
      }
      .blog-title { font-weight: 600; font-size: 14px; overflow: hidden; margin-right: 10px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical; text-transform: capitalize;
        margin-bottom: 5px;}
        p.blog-desc {
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          margin: 0;     color: #555;
      }
    }
  }
}

// blog-view
.blog-view {
  --padding-start: 20px;
  --padding-end: 20px;
  --padding-top: 1em !important;
  img { border-radius: 15px; box-shadow: 0 0 7px -3px black; width: 100%; object-fit: cover; }
  .health-tips-desc p {
    color: #555;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    text-align: justify !important;
   }
}

// add blog
.add-blog {
  .img-upload {
    width: 100%;
    height: 230px;
    background: #cbcbcb47;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    img{
      top: 50%;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  img.uploaded {
    height: 230px;
  width: 100%;
  object-fit: cover;
  border-bottom-left-radius: 35px;
  border-bottom-right-radius: 35px;
  position: absolute;
  top: 0;
  z-index: -9;
  }
  input[type=file] { opacity: 0;     position: absolute; }
  ion-label {
    font-size: 14px !important;
    font-weight: 400;
    color: var(--ion-color-light-black);
  }
}

// health tips
.blog_edit{
  margin-right: 10px;
}
.health-tips {
    ion-item{
      // display: flex;
      align-items: center;     margin-top: 10px;
      ion-thumbnail{
        width: 85px;
        height: 75px;
        border-radius: 5px;
        object-fit: cover;
        margin: 0px 10px 0px 0;
        img {
          border-radius: 8px; border: 1px solid #f5f5f5;
        }
      }
      .health-tips-title { font-weight: 600; font-size: 14px; overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical; text-transform: capitalize;
        margin-bottom: 5px; margin-right:10px; }
        p.health-tips-desc {
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          margin: 0;     color: #555;
      }
    }
}

// health tips view
.health-tips-view {
  --padding-start: 20px;
  --padding-end: 20px;
  --padding-top: 1em !important;
  img { border-radius: 15px; box-shadow: 0 0 7px -3px black; width: 100%; object-fit: cover; }
  .health-tips-desc p { color: #555;margin:2px 0px; }
}


// add health tips
.add-health-tips {
  .img-upload {
    width: 100%;
    height: 230px;
    background: #cbcbcb47;
    border-bottom-left-radius: 35px;
    border-bottom-right-radius: 35px;
    img{
      top: 50%;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  img.uploaded {
    height: 230px;
  width: 100%;
  object-fit: cover;
  border-bottom-left-radius: 35px;
  border-bottom-right-radius: 35px;
  position: absolute;
  top: 0;
  z-index: -9;
  }
  input[type=file] { opacity: 0;     position: absolute; }
  ion-label {
    font-size: 14px !important;
    font-weight: 400;
    color: var(--ion-color-light-black);
  }

}

// reviews Page
.reviews_ptet{
  // padding:5px 7px;
  h3{
    margin: 0px;
    color: var(--ion-color-warning-contrast);
  }
  p{
    margin: 0px 10px 10px;
    color: var(--ion-color-warning-contrast);
    text-align: justify;
  }
}

// viewprescription
table{
  width:100%;
  tr th, tr td{
    // border: 1px solid #ccc;
    padding: 5px;
    // text-align: center;
  }
}


// Upload report
.report-files{
  width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  img.files {
    border-radius: 10px;
    // height: 250px;
    margin: 0 auto;
    display: block;
    object-fit: cover;
    width: 100%;
  }
  .close { text-align: right; }
}
.item-native .item-inner{padding:0}

// view Prescription
.view_precp{
  --padding-start: 0px;
  --padding-end: 0px;
}
.presc_add{
  align-items: center;
}


// set availability Time
:host .calendar-day.calendar-day-active::after{
  font-weight: bold;
  background-color: #F1F1F1 !important;
  color: #333 !important;
}


// .ionic-selectable-value-item{
//   float: left !important;
//   background-color: #0673b6;
//   margin-right: 5px;
//   color: #fff;
//   font-size: 13px;
//   padding: 0px 5px;
// }
.register-div{
  .high-qualify.text-input{
    height: auto !important;
    .ionic-selectable-value-item{
      float: left !important;
      background-color: #ddd;
      margin-right: 5px;
      font-size: 13px;
      padding: 3px 4px;
      background: #ddd;
      border-radius: 5px;

    }
  }
  .lang_list.text-input{
      height: auto !important;
    .ionic-selectable-value-item{
      float: left !important;
      margin-right: 8px;
      font-size: 13px;
      padding: 3px 4px;
      background: #ddd;
      border-radius: 5px;

    }
  }
}

.edit_profiles{
  .lang_list.text-input{
    height: auto !important;
  .ionic-selectable-value-item{
    float: left !important;
    margin-right: 5px;
    font-size: 13px;
    padding: 3px 4px;
    background: #ddd;
    border-radius: 5px;

  }
  }
  .high-qualify.text-input
  {
    height: auto !important;
    .ionic-selectable-value-item {
      float: left !important;
      background-color: #ddd;
      margin-right: 5px;
      font-size: 13px;
      padding: 3px 4px;
      background: #ddd;
      border-radius: 5px;
  }
  }
}
.native-textarea.sc-ion-textarea-md{
  // padding-bottom: 5px !important;
  // padding-top: 5px;
}
.textarea_notes{
  .textarea-wrapper.sc-ion-textarea-md{
    display: block !important;
  }
}

.ion-text-descr{
  height: 90px;
  overflow: auto;
  .native-textarea.sc-ion-textarea-md{
    overflow: auto !important;
    height: 90px;
    padding-right:10px;
  }
}
// .preffered_lab_lifa-list .ionic-selectable-cover
// {
//   position: unset !important;
// }
// .category_list .ionic-selectable-cover
// {
//   position: unset !important;
// }


// no internet

.nointernet{
  .alert-wrapper{
    width: 100vw !important;
    height: 100vh !important;
    max-width: 100vw !important;
    max-height: 100vh !important;
    .alert-message.sc-ion-alert-md {
      max-height: 100%;
      font-size: 16px;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
  }
  #alert-2-msg{
    margin: 100px auto;
    display: block;
  }
  }
  p{
    text-align: center;
  }
}
.calendar .calendar-inner .calendar-body{
  padding: 10px;
}

.back_icon{
  font-size: 25px;
  padding-left:10px;
}

// blog edit
.text-scroll{
  overflow-y: auto !important;
  height: 110px !important;
}

.text-scroll .native-textarea.sc-ion-textarea-md {
  overflow-y: scroll;
  height: 110px !important;
}

h5.study-deg {
  margin-bottom: 5px;
}

.ql-container.ql-snow{
  border: 2px solid #C4C4C4 !important;
  border-radius: 8px !important;
  height: 80px;
}

.health-tips-content p {
  margin: 4px 0px !important;
  padding:0px 4px;
}

.ql-editor.ql-blank::before {
  content: ("write text here..." );
  font-size:14px;
  font-weight: 400;
}

// .swiper-wrapper {height: 100vh!important; overflow: scroll!important;}

